import React from 'react';
import { BrowserRouter,Navigate, Route, Routes } from "react-router-dom";
import HomePage from "./pages/Homepage";
import './App.css';

function App() {
  return (
    <BrowserRouter>
       <Routes>
         <Route path="/" element={<HomePage />} />
         <Route path="*" element={<Navigate to="/" />} />
       </Routes>
     </BrowserRouter>
  );
}

export default App;
